import type { ToasterToast } from "@/components/ui/use-toast";

export type ToastMessages =
  | "invalid_magic_link"
  | "verification_failed"
  | "verification_successful"
  | "application_deleted"
  | "session_id_missing"
  | "nonce_missing"
  | "nonce_not_set"
  | "nonce_invalid"
  | "session_id_invalid"
  | "failed_to_retrieve_jwt";

export const toastMessages: Record<ToastMessages, Partial<ToasterToast>> = {
  // Error messages
  invalid_magic_link: {
    description:
      "The sign in link you used is invalid or has expired. Please request a new one.",
    variant: "destructive",
    title: "Error",
  },
  verification_failed: {
    description: "Email verification failed. Please try again.",
    variant: "destructive",
    title: "Error",
  },

  // Success messages
  verification_successful: {
    description: "Your email has been successfully verified!",
    variant: "success",
  },

  // Remove messages
  application_deleted: {
    description: "Application deleted successfully.",
    variant: "remove",
  },
  session_id_missing: {
    description: "Failed to authenticate. Please try again. (E0021)",
    variant: "destructive",
    title: "Error",
  },
  nonce_missing: {
    description: "Failed to authenticate. Please try again. (E0022)",
    variant: "destructive",
    title: "Error",
  },
  session_id_invalid: {
    description: "Failed to authenticate. Please try again. (E0023)",
    variant: "destructive",
    title: "Error",
  },
  nonce_not_set: {
    description: "Failed to authenticate. Please try again. (E0024)",
    variant: "destructive",
    title: "Error",
  },
  nonce_invalid: {
    description: "Failed to authenticate. Please try again. (E0025)",
    variant: "destructive",
    title: "Error",
  },
  failed_to_retrieve_jwt: {
    description: "Failed to authenticate. Please try again. (E0026)",
    variant: "destructive",
    title: "Error",
  },
};
